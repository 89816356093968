<script setup lang="ts">
import { useNewsletter } from '#imports';

const { isBusy, showDialog, values, onSubmit } = useNewsletter();
</script>

<template>
  <div id="newsletter">
    <div class="flex flex-col gap-y-10">
      <h2 class="font-bold">{{ $t('global.joinournewsletter') }}</h2>
      <p class="text-small mb-5">{{ $t('newsletter.headerdesc') }}</p>

      <form
        class="flex items-start justify-between gap-x-15 h-[80px]"
        @submit.prevent="onSubmit"
      >
        <ApzInput
          name="email"
          data-testid="newsletter-email-input"
          variant="outline"
          :placeholder="$t('global.enteremail')"
          class="h-35 rounded"
          wrapper-class="grow"
        />

        <ApzButton
          data-testid="newsletter-subscribe-button"
          type="primary"
          native-type="submit"
          :disabled="!values.email"
          :loading="isBusy"
          class="!h-35 rounded w-[95px] !text-small"
        >
          {{ $t('newsletter.subscribe') }}
        </ApzButton>
      </form>
    </div>
  </div>

  <ApzDialog
    v-model="showDialog"
    :title="$t('newsletter.newsletter')"
    :description="$t('newsletter.success')"
    :actions="[{ text: $t('global.dismiss'), onPress: () => (showDialog = false) }]"
    illustration="submit_success"
  />

  <ClientOnly>
    <ApzToast
      container="#newsletter"
      group="newsletter"
    />
  </ClientOnly>
</template>
